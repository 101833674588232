import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect
} from "react"
import { graphql, navigate } from "gatsby"
import { useMachine, useSelector } from "@xstate/react"
import axios from "axios"
import { useQuery } from "react-query"
import { TeachingSeriePageQuery } from "../../../../graphql/types"
import { PageProps, PageContext } from "../../../types/page"
import { mapProductListToRelatedProductsView } from "../../store/mappers/related-products-mapper"
import { mapSourceTeachingSerieToView } from "../mappers/teaching-serie-mapper"
import { TeachingSeriePage } from "../components/teaching-serie-page/teaching-serie-page"
import { Page } from "../../../components/page/page"
import { TopicWithArticles } from "../../../types/related-resources"
import { mapPaidContentVariants } from "../mappers/paid-content-variants-mapper"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"
import { BreadcrumbResource } from "../../../components/breadcrumbs/breadcrumbs"
import { PathPrefix } from "../../../core/constants"
import {
  ContentLockBootstrapDataKey,
  contentUnlockedMachine
} from "../../../machines/contentLockMachine.machine"
import { IServiceContext } from "../../../contexts/service-context"
import { useFeatureFlag } from "@features/use-feature-flags"
import { ContentLockValues } from "@features/feature-flag-constants"
import { AuthContext, useAuthTokens } from "@contexts/authContext"

export const ServiceContext = createContext<IServiceContext | null>(null)

export interface TeachingSeriePageContext extends PageContext {
  createdAt: string
  teacherId: string
  topicCodes: string[]
  topicId: string
  mediaId: string | null
  contentfulId: string
  seriesContentfulId: string
}

export interface TeachingSeriePageProps<Query, InitialData>
  extends PageProps<Query, InitialData> {
  pageContext: TeachingSeriePageContext
}

export const TeachingSerieTemplate: FunctionComponent<
  TeachingSeriePageProps<
    TeachingSeriePageQuery,
    {
      topicArticles: TopicWithArticles
    }
  >
> = ({ data, ...props }) => {
  const [{ context }, send, contentLockService] = useMachine(
    contentUnlockedMachine
  )
  const contentLockFlag = useFeatureFlag("contentLock")
  const { mediaId, contentfulId, seriesContentfulId } = props.pageContext
  const teachingSerie = mapSourceTeachingSerieToView(data!, mediaId)

  const { service: authMachine } = useContext(AuthContext)
  const { accessToken } = useAuthTokens()
  const isMinistryPartner = useSelector(authMachine, ({ context }) => {
    return context.isMinistryPartner
  })

  const {
    data: seriesAccess,
    isFetched: seriesAccessFetched,
    isLoading
  } = useQuery(
    `teaching_series_access_${teachingSerie?.ligCode}`,
    async () => {
      try {
        const { data: seriesAccessResponse } = await axios.get<{
          access: boolean
        }>(
          `${process.env.GATSBY_DJANGO_REDIRECT_URL}/api/v1/user/library/?item_code=${teachingSerie?.ligCode}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        return seriesAccessResponse
      } catch (e) {
        console.error(`Error with fetching series access: `, e)

        return { access: false }
      }
    },
    {
      enabled:
        Boolean(accessToken) && !isMinistryPartner && !teachingSerie?.freeStream
    }
  )

  useEffect(() => {
    if (context?.teachingSerie.contentLockFlag !== contentLockFlag) {
      send({
        type: "UPDATE_CONTENT_LOCK_FLAG",
        data: {
          key: ContentLockBootstrapDataKey.teachingSerie,
          contentLockFlag
        }
      })
    }
  }, [contentLockFlag])

  useEffect(() => {
    const shouldBootstrapContentLock =
      !context.teachingSerie.isContentUnlocked &&
      typeof context.teachingSerie.isContentUnlocked === "object" &&
      !context.teachingSerie.contentLockFlag &&
      contentLockFlag &&
      teachingSerie

    if (shouldBootstrapContentLock) {
      send({
        type: "BOOTSTRAP_CONTENT_LOCK_STATE",
        data: {
          loading: false,
          ligCode: teachingSerie.currentVideo.ligCode,
          title: teachingSerie.currentVideo.title,
          isAlwaysUnlocked: teachingSerie.freeStream || false,
          isMaintenanceMode:
            contentLockFlag === ContentLockValues.contentLockMaintenance,
          contentLockFlag,
          isContentUnlocked: false,
          key: ContentLockBootstrapDataKey.teachingSerie
        }
      })
    }
  }, [context, contentLockFlag, teachingSerie])

  const relatedProducts = mapProductListToRelatedProductsView(
    data!.contentfulSeries!.relatedProducts
  )

  const paidContentVariants = mapPaidContentVariants(data?.product)

  if (!teachingSerie) {
    navigate("/404")

    return null
  }

  const seriesData = data?.contentfulSeries
  const { metadata } = seriesData || {}

  const metadataOverrides = {
    title: metadata?.title || teachingSerie.metadata.title,
    description: metadata?.description || teachingSerie.metadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || teachingSerie.metadata.openGraphImage,
    noIndex:
      teachingSerie.slug === "test-free-series-win98mc" ||
      teachingSerie.slug === "test-paid-series-win99mc" // Add noIndex to mock series pages used for testing
  }

  const pageBreadcrumbs: BreadcrumbResource[] = [
    ...PageBreadcrumbs.TeachingSeries,
    {
      label: teachingSerie.title,
      link: `${PathPrefix.Series}/${teachingSerie.slug}`
    }
  ]

  if (mediaId) {
    pageBreadcrumbs.push({
      label: teachingSerie.currentVideo.title,
      link: `${props.uri}`
    })
  }

  const canViewPremiumSeries =
    isMinistryPartner ||
    (!seriesAccessFetched ? Boolean(accessToken) : seriesAccess?.access)

  return (
    <Page
      {...props}
      metadata={{ ...teachingSerie.metadata, ...metadataOverrides }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: pageBreadcrumbs
      }}
    >
      <ServiceContext.Provider
        value={{
          contentLockService,
          canViewPremiumSeries,
          isLoading,
          alwaysUnlocked: teachingSerie.freeStream
        }}
      >
        <TeachingSeriePage
          teachingSerie={teachingSerie}
          paidContentVariants={paidContentVariants}
          relatedProducts={relatedProducts}
          contentfulId={contentfulId}
          seriesContentfulId={seriesContentfulId}
          mediaId={mediaId || undefined}
        />
      </ServiceContext.Provider>
    </Page>
  )
}

export default TeachingSerieTemplate

export const query = graphql`
  query TeachingSeriePage(
    $id: String
    $topicCodes: [String]
    $seriesContentfulId: String
  ) {
    contentfulSeries(id: { eq: $id }) {
      ...Series
    }
    allContentfulTopic(
      filter: { topicCode: { in: $topicCodes } }
      sort: { fields: topicCode }
    ) {
      ...TopicDetailsList
    }
    product(
      teachingSeriesSlug: { eq: $seriesContentfulId }
      showOnPaywall: { eq: "true" }
    ) {
      ...ProductVariantsOnly
    }
  }
`
