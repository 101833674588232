import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  ContentfulSubfooter,
  FooterNavigationQuery
} from "../../../../graphql/types"
import { mapSourceNavigationToView } from "../mappers/navigation-mapper"

export const useFooterNavigation = () => {
  const queryResult: FooterNavigationQuery = useStaticQuery(graphql`
    query FooterNavigation {
      contentfulSubfooter {
        title
        image {
          gatsbyImageData(width: 200, height: 280, quality: 100)
        }
        sections {
          id
          title
          subtitle {
            subtitle
          }
          buttonUrl
          buttonText
          icon {
            gatsbyImageData # This will be null if the icon is an svg
            file {
              url
            } # This will likely be a svg, so we may need to use the url
          }
        }
      }
      allContentfulNavigation(filter: { location: { eq: "footer" } }) {
        edges {
          node {
            ...NavigationItem
          }
        }
      }
      allEvent(limit: 4, sort: { fields: [startDate], order: ASC }) {
        nodes {
          startDate
          date: startDate(formatString: "MMM. DD, YYYY")
          endDate
          title
          url
          type
        }
      }
    }
  `)

  return useMemo(() => {
    const { allContentfulNavigation, allEvent, contentfulSubfooter } =
      queryResult

    const navigationNode = allContentfulNavigation.edges[0].node

    const sections = mapSourceNavigationToView(navigationNode, allEvent.nodes)

    return { sections, subfooter: contentfulSubfooter as ContentfulSubfooter }
  }, [queryResult])
}
