import { FluidObject } from "gatsby-image"
import { IconVariant } from "../components/icon/icon"
import { TranslateableString } from "./intl"

export type ResourceType =
  | "article"
  | "guide"
  | "collection"
  | "conference"
  | "devotional"
  | "event"
  | "mediaResource"
  | "podcastEpisode"
  | "podcastShow"
  | "post"
  | "product"
  | "question"
  | "series"
  | "sermon"
  | "video"

export const resourceIconVariant: Record<ResourceType, IconVariant> = {
  article: "12-article",
  guide: "12-article",
  collection: "12-collection",
  conference: "12-collection",
  devotional: "12-book",
  event: "12-collection",
  mediaResource: "12-video",
  podcastEpisode: "12-audio",
  podcastShow: "12-audio",
  post: "12-article",
  product: "12-tag",
  question: "12-article",
  series: "12-collection",
  sermon: "12-audio",
  video: "12-video"
}

export interface Resource {
  typeForIcon?: ResourceType
  id?: string
  title: string
  subtitle?: TranslateableString
  bodyText?: string
  type?: ResourceType
  slug: string
  url?: string
  time?: number | null
  avatar?: string
  image?: string
  imageFluid?: FluidObject
  datePublished?: number
  component?: any
}

export interface RelatedResources {
  title: TranslateableString
  link: string
  body: string
  labelId: string
  articles?: Resource[]
}
