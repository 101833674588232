import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleCode } from "../../../i18n/locales"
import { HeaderNavigationQuery } from "../../../../graphql/types"
import { NavigationSection } from "../navigation-types"
import { mapSourceNavigationToView } from "../mappers/navigation-mapper"

export const useHeaderNavigation = (
  locale: LocaleCode
): NavigationSection[] => {
  const queryResult: HeaderNavigationQuery = useStaticQuery(graphql`
    query HeaderNavigation {
      allContentfulNavigation(filter: { location: { eq: "header" } }) {
        edges {
          node {
            ...NavigationItem
          }
        }
      }
      allEvent(limit: 6, sort: { fields: [startDate], order: ASC }) {
        nodes {
          startDate
          date: startDate(formatString: "MMM. DD, YYYY")
          endDate
          title
          url
          type
        }
      }
    }
  `)

  return useMemo(() => {
    const { allContentfulNavigation, allEvent } = queryResult

    const navigationNode = allContentfulNavigation.edges[0].node

    const sections = mapSourceNavigationToView(navigationNode, allEvent.nodes)

    return sections
  }, [locale, queryResult])
}
